import { OPEN_IN_EMBED_ENABLED } from '../../common/constants/experiments/popout';
import { selectIsDiscord } from '../../common/selectors/app';
import { selectExperiment } from '../../common/selectors/config';

export default function canExecutePlayerPopout(state) {
  const openInEmbedEnabled = selectExperiment(state, OPEN_IN_EMBED_ENABLED);
  const isDiscord = selectIsDiscord(state);

  return openInEmbedEnabled && !isDiscord;
}
