import isFunction from 'lodash/isFunction';
import {
  TRACK_GO_PREMIUM_VIEW,
  TRACK_OPT_IN_CONFIRMATION_VIEW,
  TRACK_SIGN_UP_VIEW,
  TRACK_SUBSCRIPTION_VIEW,
} from 'src/common/actions/pageTracking';
import * as AmpBingTracking from '../analytics/ampBing/ampBingTracking';
import { gaLogOptInComplete } from '../analytics/google/gaTracking';
import * as YahooPixelTracking from '../analytics/yahoo/yahooPixelTracking';

const actionTrackingOperationMap = {
  [TRACK_GO_PREMIUM_VIEW]() {
    AmpBingTracking.trackGoPremiumLandingPage();
    YahooPixelTracking.trackGoPremiumLandingPage();
  },
  [TRACK_SIGN_UP_VIEW]() {
    AmpBingTracking.trackSignUpPage();
    YahooPixelTracking.trackSignUpPage();
  },
  [TRACK_SUBSCRIPTION_VIEW]() {
    AmpBingTracking.trackSubscriptionPage();
    YahooPixelTracking.trackSubscriptionPage();
  },
  [TRACK_OPT_IN_CONFIRMATION_VIEW]({ purchasePrice }) {
    AmpBingTracking.trackOptInConfirmationPage();
    YahooPixelTracking.trackOptInConfirmationPage();
    gaLogOptInComplete(purchasePrice);
  },
};

const pageTrackingMiddleware = () => (next) => (action) => {
  const trackingOperation = actionTrackingOperationMap[action.type];

  if (isFunction(trackingOperation)) {
    trackingOperation(action.meta);
  }

  return next(action);
};

export default pageTrackingMiddleware;
