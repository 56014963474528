class MintSingleton {
  #mint;
  #stateUpdateQueue = [];
  #isReady = false;
  #readyPromise;

  get instance() {
    return this.#mint;
  }

  get isReady() {
    return this.#isReady;
  }

  get readyPromise() {
    return this.#readyPromise?.promise;
  }

  setup() {
    this.#readyPromise = Promise.withResolvers();
  }

  async init(options) {
    this.#mint = await import('@tunein/mint').then((module) => module.default);

    this.#mint.init(options);

    if (this.#stateUpdateQueue.length) {
      this.#stateUpdateQueue.forEach(([key, value]) => {
        this.#mint.updateState(key, value);
      });
    }

    this.#isReady = true;

    this.#readyPromise.resolve();

    // Cleanup for garbage collector
    this.#stateUpdateQueue = null;
    this.#readyPromise = null;
  }

  updateState(key, value) {
    if (!this.#isReady) {
      this.#stateUpdateQueue.push([key, value]);
      return;
    }

    this.#mint.updateState(key, value);
  }
}

export const mintSingleton = new MintSingleton();
