import { desktopFuncNames } from '../../common/constants/desktop';
import invokeDesktopFunction from '../../common/utils/desktop/invokeDesktopFunction';
import isDesktop from '../../common/utils/desktop/isDesktop';

export default () => {
  if (isDesktop()) {
    invokeDesktopFunction(desktopFuncNames.reloadPage);
  } else {
    window.location.reload();
  }
};
