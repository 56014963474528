import { PREROLL_PLAYED } from 'src/common/constants/player/preroll';
import config from '../../common/config';
import localStorage from '../utils/localStorage';
import { POPUP_PLAYER_READY, UPDATE_POPUP_PLAYER } from './constants/message';
import openPopoutPlayerWindow from './utils/openPopoutPlayerWindow';

const { insecureEmbedOrigin } = config;

const dimensions = {
  width: 640,
  height: 100,
};

export default class PopoutPlayerWindow {
  constructor() {
    this.popoutWindow = null;
    this.guideId = null;
    this.isPremium = null;
    this.handlePopoutPremiumStatusEvent =
      this.handlePopoutPremiumStatusEvent.bind(this);
  }

  get isWindowOpen() {
    return !!(this.popoutWindow && !this.popoutWindow.closed);
  }

  get embedUrl() {
    return `${insecureEmbedOrigin}/embed/player/${this.guideId}?partnerId=${this.partnerId}`;
  }

  handlePopoutPremiumStatusEvent(event) {
    const { data, origin } = event;
    const type = data?.type;
    const isValidMessage =
      type === POPUP_PLAYER_READY && origin === insecureEmbedOrigin;

    if (isValidMessage) {
      this.send(this.guideId, this.isPremium);
    }
  }

  open(guideId, isPremium, partnerId) {
    this.guideId = guideId;
    this.isPremium = isPremium;
    this.partnerId = partnerId;
    // this is used as a way to communicate the premium status once the popout window loads
    window.addEventListener('message', this.handlePopoutPremiumStatusEvent);
    this.popoutWindow = openPopoutPlayerWindow(
      this.embedUrl,
      dimensions.width,
      dimensions.height,
    );
  }

  focus() {
    this.popoutWindow?.focus();
  }

  send(guideId, isPremium) {
    this.focus();

    if (guideId !== this.guideId) {
      this.guideId = guideId;
      this.popoutWindow?.location.replace(this.embedUrl);
      return;
    }

    const payload = {
      type: UPDATE_POPUP_PLAYER,
      isPremium,
      prerollPlayed: JSON.parse(localStorage.get(PREROLL_PLAYED)) === true,
    };

    this.popoutWindow?.postMessage(payload, insecureEmbedOrigin);
  }

  close() {
    window.removeEventListener('message', this.handlePopoutPremiumStatusEvent);
    this.popoutWindow?.close();
  }
}
