import apiClientEffectsMiddleware from 'src/common/middleware/apiClientEffects';
import configureStore from 'src/common/store/configureStore';
import { isDevBuild } from 'src/common/utils/environment';
import { POSITION_CHANGED } from '../../common/actions/tuner';
import { SET_PLAYBACK_HISTORY } from '../../common/constants/playbackHistory';
import dialogMiddleware from '../middleware/dialogMiddleware';
import executeActionNeedingAuth from '../middleware/executeActionNeedingAuth';
import logInfo from '../middleware/logInfo';
import pageTrackingMiddleware from '../middleware/pageTrackingMiddleware';
import popoutPlayerMiddleware from '../middleware/popoutPlayerMiddleware';
import refreshNowPlayingMiddleware from '../middleware/refreshNowPlayingMiddleware';
import createTunerMiddleware from '../middleware/tuner';
import unauthenticateMiddleware from '../middleware/unauthenticate';
import apiClientEffectsErrorCallback from '../utils/apiClientEffectsErrorCallback';
import captureReduxError from '../utils/captureReduxError';

const OMITTED_ACTION_TYPES = {
  // these actions are emitted on every player position update
  [POSITION_CHANGED]: true,
  [SET_PLAYBACK_HISTORY]: true,
};

export default function configureClientStore(
  initialState,
  history,
  tuneInApiClient,
) {
  const envMiddleware = [
    createTunerMiddleware,
    unauthenticateMiddleware,
    pageTrackingMiddleware,
    refreshNowPlayingMiddleware,
    executeActionNeedingAuth,
    dialogMiddleware,
    logInfo,
    popoutPlayerMiddleware,
  ];

  if (isDevBuild()) {
    // use require since redux-logger won't be included in production scripts
    // eslint-disable-next-line global-require
    const { createLogger } = require('redux-logger');

    envMiddleware.push(
      createLogger({
        collapsed: true,
        // predicate determines whether to log actions to the console
        // see window.tiDebug (defined in client/index.js)
        predicate: (getState, action) =>
          !OMITTED_ACTION_TYPES[action.type] &&
          (getState()?.app?.debug?.showActionLogs ?? true),
      }),
    );
  }

  return configureStore(
    initialState,
    envMiddleware,
    apiClientEffectsMiddleware(
      tuneInApiClient,
      apiClientEffectsErrorCallback(tuneInApiClient),
    ),
    captureReduxError,
  );
}
