import { LOGOUT, UNAUTHENTICATE } from '../../common/actions/auth';

import { strAcctId } from '../../common/constants/cookies';
import { removeCookie } from '../../common/utils/cookie';

import { fulfilled } from '../../common/reducers/utils/asyncActionNameSuffixes';
import { doFBSignOut } from '../../common/utils/auth/fbAuth';
import { doGoogleSignOut } from '../../common/utils/auth/googleAuth';

const logoutMiddleware = () => (next) => (action) => {
  if (action.type === fulfilled(LOGOUT) || action.type === UNAUTHENTICATE) {
    // Figured if we logout, we can go ahead and log the user out from our app as well.
    doGoogleSignOut();
    doFBSignOut();
    // Clears the stripe customer id.
    removeCookie(strAcctId.name);
  }

  return next(action);
};

export default logoutMiddleware;
