import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isAuthFulfilledActionType from 'src/common/utils/auth/isAuthFulfilledActionType';

const PATH_TO_ACTION_NEEDING_AUTH = 'loginDetails.actionNeedingAuth';

const executeActionNeedingAuth =
  (middlewareApi) => (next) => async (action) => {
    const { type } = action;
    const actionNeedingAuth = get(
      middlewareApi.getState(),
      PATH_TO_ACTION_NEEDING_AUTH,
    );

    if (isAuthFulfilledActionType(type) && isFunction(actionNeedingAuth)) {
      try {
        await Promise.resolve(actionNeedingAuth());
      } catch (ex) {
        // noop
      }
    }
    return next(action);
  };

export default executeActionNeedingAuth;
