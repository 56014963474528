import {
  AUTHENTICATION_SUCCESSFUL,
  LOGOUT,
  UNAUTHENTICATE,
} from '../../common/actions/auth';
import {
  FAVORITE_CONTAINER_ITEM,
  FAVORITE_CURRENTLY_PLAYING,
  FAVORITE_PROFILE,
  UNFAVORITE_CONTAINER_ITEM,
  UNFAVORITE_CURRENTLY_PLAYING,
  UNFAVORITE_PROFILE,
} from '../../common/actions/favorites';
import { nowPlayingChanged } from '../../common/actions/tuner';
import { fulfilled } from '../../common/reducers/utils/asyncActionNameSuffixes';
import { tunerSingleton } from '../tuner';

const types = {
  [UNAUTHENTICATE]: true,
  [fulfilled(LOGOUT)]: true,
  [AUTHENTICATION_SUCCESSFUL]: true,
  [fulfilled(FAVORITE_PROFILE)]: true,
  [fulfilled(UNFAVORITE_PROFILE)]: true,
  [fulfilled(FAVORITE_CURRENTLY_PLAYING)]: true,
  [fulfilled(UNFAVORITE_CURRENTLY_PLAYING)]: true,
  [fulfilled(FAVORITE_CONTAINER_ITEM)]: true,
  [fulfilled(UNFAVORITE_CONTAINER_ITEM)]: true,
};

/**
 *  middleware which is responsible for fetching now playing data
 *  when certain actions types occur
 * @param getState
 * @param dispatch
 * @returns {function(*): Function}
 */
const refreshNowPlayingMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    const { tunedGuideId } = getState().player;

    if (!types[action.type] || !tunedGuideId) {
      return;
    }

    try {
      const nowPlaying =
        await tunerSingleton.instance?.fetchCurrentNowPlaying(tunedGuideId);
      if (nowPlaying) {
        dispatch(nowPlayingChanged(nowPlaying));
      }
    } catch (ex) {
      // eslint-disable-next-line
      console.error('Now Playing fetch Error', ex);
      // noop
    }
  };

export default refreshNowPlayingMiddleware;
