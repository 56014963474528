import { requestPopoutPlayerDialog } from '../../common/actions/dialog';
import {
  OPEN_POPOUT_PLAYER,
  setPopoutPlayerStatus,
} from '../../common/actions/player';
import { FAILED, PLAYING } from '../../common/actions/tuner';
import { selectIsUserSubscribed } from '../../common/selectors/me';
import PopoutPlayerWindow from '../popout/PopoutPlayerWindow';
import canExecutePlayerPopout from '../utils/canExecutePlayerPopout';

const popout = new PopoutPlayerWindow();

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    // Move the action down the middleware pipeline first since
    // nothing here needs to execute before the action makes it
    // to the reducers.
    next(action);

    const state = getState();
    const {
      player: { tunedGuideId },
      app: { partnerId },
    } = state;
    const { type, failedWithHttp } = action;

    switch (type) {
      case PLAYING:
        if (popout.isWindowOpen) {
          popout.close();
        }
        break;
      case FAILED:
        if (failedWithHttp && canExecutePlayerPopout(state)) {
          dispatch(setPopoutPlayerStatus());
          dispatch(requestPopoutPlayerDialog());
        }
        break;
      case OPEN_POPOUT_PLAYER: {
        const isPremium = selectIsUserSubscribed(state);
        if (popout.isWindowOpen) {
          popout.send(tunedGuideId, isPremium);
        } else {
          popout.open(tunedGuideId, isPremium, partnerId);
        }
        break;
      }
      default:
    }
  };
