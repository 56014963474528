import * as Sentry from '@sentry/browser';
import isError from 'lodash/isError';
import shouldLogToSentry from '../../common/utils/shouldLogToSentry';

function safeStringify(obj) {
  try {
    const string = JSON.stringify(obj);
    return { string };
  } catch (error) {
    return { error };
  }
}

export default function captureReduxError(err, store, action) {
  if (!shouldLogToSentry()) {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }

    return;
  }

  if (err) {
    const extra = {
      redux: true,
      action: {
        // not including the rest of action
        // due to potential of sensitive data being included
        type: action?.type,
      },
    };

    if (!isError(err)) {
      const { string: dump, error: dumpError } = safeStringify(err);

      extra.isNotError = true;
      if (dump) extra.dump = dump;
      if (dumpError) extra.dumpError = dumpError;
    }

    Sentry.captureException(err, { extra });
  } else if (action?.type) {
    // sometimes action here is a function that dispatches an action
    // which will come in to here a second time as the actual action once dispatched
    // so we are ignoring everything else here that does not have a
    // type property to prevent "null" breadcrumbs and reduce load on sentry
    Sentry.addBreadcrumb({
      category: 'redux',
      message: action?.type,
    });
  }
}
