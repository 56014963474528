import get from 'lodash/get';
import omit from 'lodash/omit';
import isEqual from 'react-fast-compare';
import parseQuery from 'src/common/utils/queryString/parse';

const authPath = 'location.state.authenticated';

export default function reloadOnPropsChange(props, nextProps) {
  const wasAuthed = get(props, authPath);
  const isAuthed = get(nextProps, authPath);
  // reload the page
  // if the route change was triggered by withAuthHandling
  // and differs from the previous state
  const hasAuthChanged = (wasAuthed || isAuthed) && wasAuthed !== isAuthed;
  const propsQuery = parseQuery(props.location.search);
  const nextPropsQuery = parseQuery(nextProps.location.search);

  return (
    hasAuthChanged ||
    props.location.pathname !== nextProps.location.pathname ||
    // This covers the case where the query params change and that change is not due to a change in
    // the topicId query param value (we do not want to reload on topicId changes).
    !isEqual(omit(propsQuery, 'topicid'), omit(nextPropsQuery, 'topicid'))
  );
}
