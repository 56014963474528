import * as Sentry from '@sentry/browser';
import formatClientErrorData from 'src/common/utils/formatClientErrorData';
import { safeJsonStringify } from 'src/common/utils/object/safeJsonStringify';

export default function apiClientEffectsErrorCallback(tuneInApiClient) {
  return (error, actionType) => {
    const errorData = formatClientErrorData({
      message: 'client apiClientEffectsMiddleware fetch error',
      context: {
        error,
        actionType,
      },
    });

    // If request was rate-limited, log error to Sentry
    if (error?.response?.status === 429) {
      const { status, statusText, data } = error?.response || {};

      Sentry.captureException('Network request rate-limited', {
        extra: {
          actionType,
          url: error?.request?.url,
          status,
          statusText,
          // 128 characters seems sufficient to get extra failure context, if any. Can be removed later if not useful.
          data: safeJsonStringify(data)?.slice(0, 128),
        },
      });
    }

    tuneInApiClient?.logging?.errorLog(errorData).catch(() => {}); // catch prevents error from propagating and creating a Sentry error
  };
}
