export default {
  get(key) {
    const item = global.localStorage?.getItem(key);

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  },

  set(key, value) {
    try {
      const item = JSON.stringify(value);
      global.localStorage.setItem(key, item);
    } catch (e) {
      // noop
    }
  },

  remove(key) {
    global.localStorage.removeItem(key);
  },

  getOrSetDefault(key, defaultValue) {
    const item = this.get(key);

    if (!item) {
      this.set(key, defaultValue);
      return defaultValue;
    }

    return item;
  },
};
