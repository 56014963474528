import { pick } from '@tunein/web-utils';
import {
  AUTHENTICATION_SUCCESSFUL,
  openUserRegistrationDialogWithAuthViewAttribute,
} from '../../common/actions/auth';
import {
  CLOSE_DOWNLOAD_APP_DIALOG,
  OPEN_DOWNLOAD_APP_DIALOG,
  OPEN_INTEREST_SELECTOR_DIALOG,
  closeNowPlayingDialog,
  openDownloadAppDialogAndTrack,
  openNowPlayingDialog,
} from '../../common/actions/dialog';
import { PLAY } from '../../common/actions/player';
import canShowInstallAppDialog from '../../common/actions/utils/dialog/canShowInstallAppDialog';
import viewTypes from '../../common/constants/auth/viewTypes';
import registrationViews from '../../common/constants/dialogs/registrationViews';
import { selectIsFord } from '../../common/selectors/app';
import { selectIsUserSubscribed } from '../../common/selectors/me';

const validActions = [
  PLAY,
  AUTHENTICATION_SUCCESSFUL,
  OPEN_DOWNLOAD_APP_DIALOG,
  CLOSE_DOWNLOAD_APP_DIALOG,
  OPEN_INTEREST_SELECTOR_DIALOG,
];

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const { type } = action;
    const state = getState();

    if (!validActions.includes(type) || selectIsFord(state)) {
      next(action);
      return;
    }

    const {
      dialog: { isNowPlayingDialogOpen },
      player: { tunedGuideId },
    } = state;
    const { source, viewTypeData } = action?.meta || {};
    const { viewType, attribute } = pick(viewTypeData, [
      'viewType',
      'attribute',
    ]);

    switch (type) {
      case PLAY:
        if (!isNowPlayingDialogOpen && canShowInstallAppDialog(state)) {
          dispatch(openDownloadAppDialogAndTrack());
        } else {
          dispatch(openNowPlayingDialog());
        }
        next(action);
        break;
      case OPEN_DOWNLOAD_APP_DIALOG:
      case OPEN_INTEREST_SELECTOR_DIALOG:
        if (isNowPlayingDialogOpen) {
          dispatch(closeNowPlayingDialog());
        }
        next(action);
        break;
      case CLOSE_DOWNLOAD_APP_DIALOG:
        // Need to wait for any state updates that are triggered by the dialog closing
        // to complete before opening the next NowPlayingDialog. See mint reducer for
        // example of the use case.
        next(action);
        if (tunedGuideId) {
          dispatch(openNowPlayingDialog());
        }
        break;
      case AUTHENTICATION_SUCCESSFUL:
        next(action);

        if (selectIsUserSubscribed(getState())) {
          dispatch(
            openUserRegistrationDialogWithAuthViewAttribute(
              attribute,
              registrationViews.linkWithAlexa,
            ),
          );
          return;
        }
        // If there is a source value here, that means the user is within the sub
        // flow itself and has seen an upsell. In this case, we don't want to show
        // the dialog.
        if (viewType === viewTypes.signUp && !source) {
          dispatch(
            openUserRegistrationDialogWithAuthViewAttribute(
              attribute,
              registrationViews.goPremium,
            ),
          );
        }
        break;
      default:
        next(action);
    }
  };
