import { LOG_INFO, logClientInfo } from 'src/common/actions/logging';

const logInfo = (store) => (next) => (action) => {
  if (action.type === LOG_INFO) {
    const data = action.data[0] || {};
    store.dispatch(
      logClientInfo({
        ...data,
        context: {
          ...data.context,
          pagePath: window.location.pathname,
        },
      }),
    );
  }

  next(action);
};

export default logInfo;
