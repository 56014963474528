import assetUrl from '../../common/utils/assetUrl';

class TunerSingleton {
  #tuner;
  #isReady = false;
  #readyPromise;
  #initialVolume;

  get instance() {
    return this.#tuner;
  }

  get readyPromise() {
    return this.#readyPromise?.promise;
  }

  async init(webApiClientInstance, playerElement, tunerConfigs) {
    this.#readyPromise = Promise.withResolvers();
    this.#tuner = await import('@tunein/web-tuner').then(
      (module) => module.default,
    );

    this.#tuner.setup({
      bundleId: 'tunein.com',
      playerElement,
      blankMp3Url: assetUrl('assets/media/blank.mp3'),
      tuneInApiClient: webApiClientInstance,
      ...tunerConfigs,
    });

    this.#isReady = true;

    this.setVolume(this.#initialVolume);
    this.#readyPromise.resolve();
  }

  setVolume(volume) {
    if (this.#isReady) {
      this.#tuner.setVolume(volume);
      return;
    }

    this.#initialVolume = volume;
  }
}

export const tunerSingleton = new TunerSingleton();
