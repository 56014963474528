import { QUALIFIED_TUNE } from '../../common/actions/tuner';
import { gaReportQualifiedTune } from '../analytics/google/gaTracking';

export default () => (next) => (action) => {
  if (action.type === QUALIFIED_TUNE) {
    gaReportQualifiedTune();
  }

  return next(action);
};
